let platform;
if (window.location.href.includes("testyourapp") || window.location.href.includes("localhost")) {
    localStorage.setItem("Platform","DEV");
   
    platform="DEV"
}else if(window.location.href.includes("stage")){
    localStorage.setItem("Platform","STAGE");
    platform="STAGE"
  }
  
else if (window.location.href.includes("nu")) {
    localStorage.setItem("Platform","PROD");
    localStorage.setItem("Platform2","PROD");
    platform="PROD"
    console.log("onload platform2")
}
const backendUrl = process.env[`REACT_APP_${platform}_BACKEND_URL`];

export default {
    backendApi: backendUrl
}

window.onload = function() {
    if (window.location.href.includes("testyourapp") || window.location.href.includes("localhost")) {
        localStorage.setItem("Platform","DEV");
        console.log("I am windowondload")
    }
    else if(window.location.href.includes("stage")){
        localStorage.setItem("Platform","STAGE");
      }      
    else if (window.location.href.includes("nu")) {
        localStorage.setItem("Platform","PROD");
        localStorage.setItem("Platform2","PROD");
        console.log("i am from prod")
    }
}
