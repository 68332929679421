const sw = {
  Login: "Logga in",
  Password: "Lösenord",
  email: "E-post",
  Email: "E-post",
  emailPlaceholder: "E-post",
  PasswordPlaceholder: "Lösenord",
  ForgotPassword: "Glömt ditt lösenord?",
  DontHaveAnAccount: "Har du inget konto?",
  SignUp: "Bli medlem",
  SocialMediaTitle: "Logga in via sociala medier!",
  terms: "Användarvillkor",
  privacy: "Dataskydd",
  ResetPassword: "Återställ lösenord",
  ConfirmPassword: "Bekräfta lösenord",
  Alreadymember: "Redan medlem?",
  ForgotPasswordHeader: "Glömt ditt lösenord",
  submit: "Skicka",
  CreateAccount: "Skapa konto",
  FirstName: "Förnamn",
  LastName: "Efternamn",
  pwd1: "Innehåller minst en stor bokstav",
  pwd2: "Innehåller minst ett specialtecken",
  pwd3: "Innehåller minst ett nummer",
  pwd4: "Lösenord matchar",
  pwd5: "Minst 8 tecken lång",
  AgreeTermsUse: "Godkänner användarvillkoren?",
  GETSTARTED: "Kom igång",
  GETTOKNOWUS: "Lär känna oss",
  readmore: "Läs mer",
  REGISTERNOW: "REGISTRERA DIG NU",
  AllNews: "Alla nyheter",
  phonnumber: "Telefonnummer",
  Message: "Meddelande",
  VIEWALL: "Se allt innehåll  ",
  ViewAll: "Se allt innehåll  ",
  Back: "Tillbaka",
  Dashboard: "Utbildningsportal",
  MySpace: "Mina sidor",
  MyCourses: "Mitt innehåll",
  EddiLabs: "Eddi labs",
  EddiProfile: " Min profil",
  MyFavorites: "Mina favoriter",
  Welcome: "Välkommen, ",
  Favorite: "Favoriter",
  EddiSuggestion: "Eddi förslag",
  searchby: "Sök efter utbildningar",
  Okbutton: "OK",
  VIEW: "Läs mer",
  RateNow: "Betygsätt nu",
  aboutThisCourse: "Om denna utbildning",
  aboutThisEvent: "Om detta event",
  aboutThisNews: "About this news",
  Whatyouwilllearn: "Vad du kommer att lära dig",
  readless: "Läs mindre",
  AboutInstructor: "Om utbildningsledare",
  ENROLLNOW: "REGISTRERA DIG NU",
  CONTINUE: "Fortsätt",
  Checkout: "Betalning",
  No_RESULT_FOUND: "Ingen information tillgänglig",
  ChooseState: "Välj State",
  PayStripe: "Betala med Stripe",
  PayCard: "Betala med kort",
  PayNow: "Betala",
  CreditCard: "Kredit- eller debitkort",
  OrderSummary: "Ordersammanfattning",
  CourseFlow: "Kursflöde",
  CourseMaterial: "Kursmaterial",
  JoinyourZoomClass: "Gå med i din googleklass",
  JoinZoomMeeting: "Gå med i zoom meeting",
  JOINNOW: "GÅ MED NU",
  ZoomId: "Zoom-ID",
  EnterZoomID: "Ange Zoom ID",
  Passcode: "Lösenord",
  EnterPasscode: "Ange lösenord",
  ThriveintheSector: "Trivs i branschen",
  RegisterfortheupcomingEvents: "Anmäl dig till kommande evenemang",
  ShowingAllEvents: "Visar alla händelser",
  ShowingAllNews: "Visar alla nyheter",
  Location: "Plats",
  Startsfrom: "Börjar från",
  by: "Förbi",
  CourseType: "Kurstyp",
  NewsArticles: "Nyhetsartiklar",
  NewsEvents: "Nyheter och evenemang    ",
  WhatOn: "Aktuellt ",
  Continue: "FORTSÄTTA",
  MyProgress: "Min utveckling",
  UpcomingSchedules: "Kommande schema",
  EditProfile: "Redigera profil",
  SaveProfile: "Spara profil",
  FullName: "Fullständiga namn",
  FullNameAbout: "Förnamn och efternamn",
  EmailAddress: "E-postadress",
  changesPassword: "Ändra lösenord",
  PopularCategories: "Populära kategorier",
  CoursesInterested: "Kurser intresserade",
  AboutOrganization: "Om organisation",
  Reviews: "Recensioner",
  ViewMaterial: "Se material",
  MeetingLink: "Möteslänk",
  Home: "Hem",
  AboutEddi: "Om Eddi",
  Courses: "Utbildningar",
  ContactUs: "Kontakta oss",
  NoData: "Ingen information tillgänglig",
  Ongoing: "Pågående",
  VerifyMail: "Vänligen verifiera ditt konto genom din email",
  ThanksRating: "Tack för att du betygsätter oss",
  WhatEddiCanDo: "Vad Eddi kan göra för dig?",
  OppsAlreadyEnrolled: "Hoppsan, du har redan anmält dig till den här kursen!",
  Congratulations: "Grattis!",
  AuthFailed: "Autentisering misslyckades!..",
  UnderProcess: "Transaktion under process..",
  Failed: "Mislyckades... !",
  SuccessEnrolledEvent: "Du har anmält dig till detta event",
  Completed: "Avslutad",
  All: "Alla",
  Weekly: "Varje vecka",
  WelcomeIn: "Välkommen in",
  JustClick: "Bara ett par klick så börjar vi",
  Monthly: "En gång i månaden",
  Yearly: "Årlig",
  Related: "Relaterad",
  FavoriteCourse: "Favorit Kurs",
  DiscoverCourses: "Hitta utbildningar ",
  PopularArea: "som passar dig",
  SelectCourse: "Sök, välj och läs mer  ",
  NewCourseProfile: "Nya utbildningar för dig",
  Related: "Relaterade",
  CourseFound: "Utbildningar hittade",
  FavoriteCourse: "Mina favoriter",
  FollowUs: "Följ oss:",
  Copyright: "Copyright © 2022 Drivs av Eddi webbplats",
  QuickLinks: "Quick links",
  FooterTag: "© Eddi webbplats " + new Date().getFullYear(),
  AboutUs: "Om Eddi",
  Created: "Skapad framgångsrikt",
  Updated: "Profilen har uppdaterats framgångsrikt",
  ViewMore: "Visa mer",
  ViewLess: "Visa mindre",
  ClearAll: "Rensa alla",
  PersonalInfo: "Allmän information",
  EducationInfo: "Utbildning och kompetens",
  ProfessInfo: "Yrkesroll",
  AreaOfInt: "Intresseområden",
  CurrentProfRole: "Nuvarande yrkesroll",
  otherRole: "Ytterligare roll (om någon)",
  ExtraCurricular: "Fritidsutbildningar (språk, data m.m)",
  FutureRole: "Framtida yrkesroll",
  CoreResponsiblities: "Huvudsakliga ansvar  ",
  LevelRole: "  Rollnivå",
  Choose: "Välj ",
  EnterProfessional: "Skriv nuvarande roll  ",
  EnterFuture: "Skriv in din framtida drömroll  ",
  EnterResponsiblities: "Ange ansvar",
  Male: "Man",
  Female: "Kvinna",
  Other: "Annat",
  Next: "Nästa",
  Skip: "Hoppa över",
  ChooseCountry: "Välj land",
  Gender: "Kön",
  Enter: "Ange",
  EnterFirstName: "Ange förnamn",
  EnterLastName: "Ange efternamn",
  EnterEmailAddress: "Lägg till områden (t.ex. franska)  ",
  ChooseGender: "Välj kön",
  Enterdigit: "Ange fyrsiffrigt nummer",
  HighestEducation: "Högsta utbildningsnivå",
  Education: "utbildning ",
  UniversityName: " Utbildningsinstitution",
  EnterUniversityName: "Ange namn",
  EnterEducationArea: "Skriv utbildningsområde",
  EducationArea: "Utbildningsområde",
  RelevantEducation: "Andra relevanta utbildningar",
  EnterEducationAreas: "Skriv andra utbildningar du har",
  DiplomasandCertificates: "Examensbevis och certifikat",
  EnterDiplomasandCertificates: "Ange examensbevis och certifikat",
  ChooseSubCategory: "Välj underkategori",
  Agreetoreceive: " Vill du ta emot information om nya arbetsmöjligheter?",
  EnterAreaofInterest: "Ange intresseområde (seprated efter komma)",
  UploadImage: "Ladda upp profilbild",
  UserDashboard: "Utbildningsportal",
  Logout: "Logga ut",
  PersonalNumber: "Personligt nummer",
  Event: "Händelse",
  Price: "Pris",
  VATCharges: "Momsavgifter",
  Total: "Totalt",
  NoNotification: "Ingen avisering hittades",
  EnterAdditonal: "Skriv ytterligare roll  ",
  EnterAdditionalAreasInterest: "Lägg till ytterligare områden  ",
  Additionalareasinterest:
    "Lägg till ytterligare områden du är intresserad av  ",
  EnterExtraCurricular: "Lägg till områden (t.ex. franska)",
  Coworker: "Medarbetare",
  Teamleader: "Team-ledare",
  Manager: "Mellanchef",
  Executivemanager: "Högre chef",
  Elementaryschool: "Grundskola",
  Grammarschool: "Gymnasium",
  ProfessionalOccupationaltraining: "Yrkesutbildning",
  Bachelor: "Kandidatexamen",
  Master: "Magisterexamen",
  Phd: "Doktorsexamen",
  ChooseLan: "Välj språk",
  ChooseDob: "Födelsedatum",
  MyProfile: "Min profil",
  Membersince: "Medlem sedan",
  MeetingDetails: "Information om mötet",
  ShowingCourses: "Visa utbildningar inom",
  CoursesFound: "Utbildningar hittades",
  CourseCategory: "Utbildningskategori",
  Organization: "Organisationsutbildningar",
  FilterBy: "Filtrera efter",
  Difficultylevel: "Svårighetsgrad",
  Newest: "Nyaste",
  Oldest: "Äldsta",
  NameCard: "Kortinnehavare",
  Free: "Gratis",
  Paid: "Betalad",
  Beginner: "Nybörjare",
  Intermediate: "Intermediär",
  Advance: "Förskott",
  PaybyInvoice: "Betala med faktura",
  PayByMe: "Betala faktura själv",
  PayByOrg: "Företagsfaktura",
  NameOnCard: "Skriv för- och efternamn",
  Country: "Land",
  EnterCountry: "Ange land",
  TryAgain: "Var god försök igen !",
  vatCharges: "Momsavgifter",
  SuccessEnroll: "Du har anmält dig till den här kursen",
  EventEnrollSuccess: "Du har anmält dig till detta evenemang",
  CourseSubcategory: "Utbildning underkategori",
  EnterCity: "Skriv in stad",
  EnterZip: "Ange postnummer",
  Zip: "Postnummer",
  OrganizationBracket: "(Betalande företag/org)",
  Reference: "Referens (Betalande företag/org)",
  EnterReference: " Ange referens (t.ex. ekonomiansvarig)",
  EnterStreet: "Ange gata och nummer",
  Street: "Gata/nr (Betalande företag/org)",
  OrganizationNumber: "Organisationsnummer",
  EnterOrganizationNumber: "Ange organisationsnummer",
  OrganizationName: "Namn på företag ",
  EnterOrganizationName: "Namn på betalande organisation/företag",
  NameOfStudent: "Studentens namn",
  PersonalNumber: "Personligt antal studenter",
  EVoiceAddress: "E-fakturaadress (Betalande företag/org.)",
  ChooseEVoiceAddress: "E-fakturaadress",
  Address: "Adress",
  CoursesInterestIn: "Intresseområden",
  AllCategory: "alla kategorier",
  City: "Stad",
  OrganizationEmail: "Organization email",
  EnterOrganizationEmail: "Enter organization email",
  News: "Nyheter och artiklar",
  RecrutmentTitle: "Rekryteringsannonser",
  "Select Course Date": "Välj datum",
  "Health and Care": "Hälsa och sjukvård",
  "Administrative and Extra Curricular": "Administration och fritid",
  "School and Teaching": "Skola och undervisning",
  "Executive Education": "Ledarskap",
  "Technology and Society": "Samhälle och teknik",
  "Legal and Law": "Juridik",
  "Legal and law": "Juridik",
  "Safety academy": "Trygghetsakademin",
  "IT and information": "IT och information",
  "Marketing academy": "Marknadsakademin",
  "Executive education": "Ledarskap",
  "Business and finance": "Ekonomi och finans",
  "IT and Information": "IT och information",
  "Society and technology": "Samhälle och teknik",
  Gric: "GRC",
  GRC: "GRC",
  "Business and Finance": "Ekonomi och finans",
  Accept: "Godkänn",
  ClearFilters: "Återställ filter",
  StreetAndNumber: "Gata/nummer",
  MyOrganization: "Min organisation",
  IncludeVATCharges: "Inklusive moms",
  AgreeToReceiveNotificationInSwedish:
    "Jag önskar kommunikation från Eddi på engelska",
  OrganizationCourse: "Min organisation",
  OrderConfirmation: "Sammanställning av order/köp",
  PaidData: "Med avgift",
  FreeData: "Utan avgift",
  Learners: "anmälda",
  StartsFrom: "Börjar",
  Beginner: "Grundnivå",
  Intermediate: "Medelnivå",
  Advanced: "Avancerad nivå",
  Days: "dagar",
  January: "januari",
  February: "februari",
  March: "Mars",
  April: "april",
  May: "Maj",
  June: "juni",
  July: "juli",
  August: "augusti",
  September: "september",
  October: "oktober",
  November: "november",
  December: "december",
  WelcomeText:
    "Här lägger du in information som skapar din personliga profil på Eddi. Det tar bara några minuter, och du kan alltid gå tillbaka och ändra senare.",
  BackToHome: "Tillbaka till hemsida",
  KeyHighlights: "Delområden",
  CategoryOverview: "Sammanfattning",
  EnrollmentProcess: "Hur du blir medlem",
  Step1CreateAccount: "Steg 1 – skapa ett konto",
  Step1CreateAccountDescrption:
    "Skapa ett konto genom att tillhandahålla efterfrågad information.",
  Step2CreateAccount: "Steg 2 – skapa din profil",
  Step2CreateAccountDescrption:
    "Skapa din unika profil på eddi med kontaktuppgifter och dina intresseområden.",
  Step3CreateAccount: "Steg 3 – Matcha och sök relevant innehåll",
  Step3CreateAccountDescrption:
    "Baserat på din profil kommer Eddi föreslå olika utbildningar, events, och jobbmöjligheter för dig.",
  ViewAllCourses: "Se alla utbildningar",
  Nocoursefoundbasedonyourprofile: "Inget innehåll baserat på din profil",
  NewsAndArticles: "Nyheter och artiklar",
  Online: "Digital",
  Physical: "Fysisk",
  Hybrid: "Hybrid",
  InvoiceSentSuccessfully: "Faktura har nu skickats",
  AllCourse: "Alla utbildningar",
  AlreadyEnrolled: "Redan anmäld",
  ExternalCourseDetails:
    "Anmälan till denna utbildning hanteras av utbildningsleverantören. Klicka nedan för att komma vidare.",
  AdditionalareasinterestSeparatedByComma: "(separera med kommatecken)",
  ReadMoreAdvertisementButton: "Läs mer",
  StartOnward: "Startar",
  ExternalEventDetails:
    "Vad roligt att du vill anmäla dig till detta event. Registrering sker via arrangören.",
  ContinueButton: "Fortsätt",
  Registered: "anmälda",
  AboutOrganizer: "Om utbildningsledare",
  OurCourseCategory: "Våra utbildningsområden",
  ContactInstructor: "Kontakta lärare/admin",
  CourseContentStatus: "Innehåll och genomförande",
  StartNow: "Börja nu",
  Download: "Download",
  EnterCorporateCode: "Enter corporate code",
  CorporateCode: "Corporate code",
  Playvideo: "Spela filmen",
  OrgEventNews: "Organization event and news",
  EnrollmentToEvent: "Beställning",
  Hours: "Hours",
  COURSECATEGORY: "Utbildningsområden",
  NotStarted: "Pågående",
  ViewContent: "Visa innehåll",
  PublishedBy: "Publicerat av",
  Global: "Eddis utbildningar",
  MyOrgCourse: "Mina organisationsutbildningar",
  Pending: "Pågående",
  Ongoing: "Pågående",
  Completed: "Avslutad",
  By: "Av",
  PaymentConfirmationPending: "Inväntar bekräftelse på betalning",
  ComingSoon: "Kommer snart!",
  OurCourses: "Våra utbildningar",
  CourseCategories: "Våra ubildningsområden",
  "Business and finance": "Ekonomi och finans",
  GRC: "GRC",
  "Society and technology": "Samhälle och teknik",
  "Legal and law": "Juridik",
  "Safety academy": "Trygghetsakademin",
  "IT and information": "IT och information",
  "Marketing academy": "Marknadsakademin",
  "Executive education": "Ledarskap",
  Startsfrom: "Börjar från",
  Duration: "Längd",
  courses: "utbildningar",
};
export default sw;
