const en = {
  Login: "Login",
  email: "Email",
  Password: "Password",
  emailPlaceholder: "Email",
  PasswordPlaceholder: "Password",
  ForgotPassword: "Forgot password?",
  DontHaveAnAccount: "Don’t have an account?",
  SignUp: "Sign up",
  SocialMediaTitle: "Login via social media!",
  terms: "Terms and conditions",
  privacy: "Privacy policy",
  ResetPassword: "Reset password",
  ConfirmPassword: "Confirm password",
  Alreadymember: "Already a member?",
  ForgotPasswordHeader: "Forgot password",
  submit: "Submit",
  CreateAccount: "Create account",
  FirstName: "First name",
  LastName: "Last name",
  pwd1: "Contains at least one uppercase letter",
  pwd2: "Contains at least one special character",
  pwd3: "Contains at least one number",
  pwd4: "Passwords are matching",
  pwd5: "Minimum 8 character long",
  AgreeTermsUse: "Agree to terms of use ?",
  AgreeToReceiveNotificationInSwedish:
    "I wish to have emails and notification in English",
  GETSTARTED: "GET STARTED",
  GETTOKNOWUS: "GET TO KNOW US",
  readmore: "read more",
  REGISTERNOW: "REGISTER NOW",
  AllNews: "All News",
  phonnumber: "Phone number",
  Message: "Message",
  VIEWALL: "VIEW ALL",
  ViewAll: "View All",
  Back: "Back",
  Dashboard: "Course portal",
  MySpace: "My space",
  MyCourses: "My courses",
  EddiLabs: "Eddi labs",
  EddiProfile: "Eddi profile",
  MyFavorites: "My favorites",
  Welcome: "Welcome, ",
  WelcomeIn: "Welcome in",
  Favorite: "Favorites",
  JustClick: "Just a couple of clicks and we start",
  EddiSuggestion: "Eddi suggestions",
  searchby: "Search for courses",
  Okbutton: "OK",
  VIEW: "View",
  RateNow: "Rate now",
  aboutThisCourse: "About this course",
  aboutThisEvent: "About this event",
  aboutThisNews: "About this news",
  Whatyouwilllearn: "What you will learn",
  readless: "read less",
  AboutInstructor: "About instructor",
  ENROLLNOW: "ENROLL NOW",
  CONTINUE: "CONTINUE",
  Checkout: "Checkout",
  No_RESULT_FOUND: "No result found",
  ChooseCountry: "Choose country",
  ChooseState: "Choose state",
  PayStripe: "Pay by stripe",
  PayCard: "Pay by card",
  PayNow: "Pay now",
  CreditCard: "Credit or debit card",
  OrderSummary: "Order summary",
  CourseFlow: "Course flow",
  CourseMaterial: "Course material",
  JoinyourZoomClass: "Join your online class",
  JoinZoomMeeting: "Join zoom meeting",
  JOINNOW: "JOIN NOW",
  ZoomId: "Zoom id",
  EnterZoomID: "Enter zoom ID",
  Passcode: "Passcode",
  EnterPasscode: "Enter passcode",
  ThriveintheSector: "Thrive in the sector",
  RegisterfortheupcomingEvents: "Register for the upcoming events",
  ShowingAllEvents: "Showing all events",
  ShowingAllNews: "Showing all news",
  Location: "Location",
  Startsfrom: "Starts from",
  by: "By",
  CourseType: "Course type",
  NewsArticles: "News & articles",
  NewsEvents: "News & events",
  WhatOn: "What's on",
  Continue: "CONTINUE",
  MyProgress: "My progress",
  UpcomingSchedules: "Upcoming schedules",
  EditProfile: "Edit profile",
  SaveProfile: "Save profile",
  FullName: "Full name",
  FullNameAbout: "Full name",
  EmailAddress: "Email address",
  changesPassword: "Change password",
  PopularCategories: "Popular categories",
  CoursesInterested: "Courses interested",
  AboutOrganization: "About organization",
  Reviews: "Reviews",
  ViewMaterial: "View material",
  ViewCourse: "View Course",
  MeetingLink: "Meeting link",
  Home: "Home",
  AboutEddi: "About eddi",
  Courses: "Courses",
  ContactUs: "Contact us",
  NoData: "No data found",
  Ongoing: "Ongoing",
  Completed: "Completed",
  All: "All",
  CourseFound: "Courses found",
  FavoriteCourse: "Favorite course",
  DiscoverCourses: "Discover courses",
  PopularArea: "across popular areas",
  SelectCourse: "Search for relevant courses",
  NewCourseProfile: "New courses based on your profile",
  FollowUs: "Follow us:",
  Copyright: "Copyright © 2022 powered by eddi website",
  QuickLinks: "Quick links",
  FooterTag: "© Eddi website " + new Date().getFullYear(),
  AboutUs: "About eddi",
  Created: "Created successfully",
  Updated: "Profile updated successfully",
  ViewMore: "View more",
  ViewLess: "View less",
  PersonalInfo: "Personal information",
  EducationInfo: "Educational information",
  ProfessInfo: "Professional information",
  AreaOfInt: "Areas of interest",
  CurrentProfRole: "Current professional role ",
  otherRole: "Additional role (if any)",
  ExtraCurricular: "Extra curricular competence you want",
  FutureRole: "Future professional role?",
  CoreResponsiblities: "Core responsibilities",
  LevelRole: "Level of role",
  Choose: "Choose",
  EnterProfessional: "Enter professional role",
  EnterAdditonal: "Enter additonal role",
  EnterFuture: "Enter future professional role",
  EnterResponsiblities: "Enter responsiblities",
  Male: "Male",
  Female: "Female",
  Other: "Other",
  Next: "Next",
  Skip: "Skip",
  Gender: "Gender",
  Enter: "Enter",
  EnterFirstName: "Enter first name",
  EnterLastName: "Enter last name",
  EnterEmailAddress: "Enter email address",
  ChooseGender: "Choose gender",
  Enterdigit: "Enter 4 digit number ",
  HighestEducation: "Highest level of education",
  UniversityName: "Educational institution ",
  EnterUniversityName: "Enter university name",
  EnterEducationArea: "Enter education area",
  EducationArea: "Education area",
  RelevantEducation: "Other relevant education",
  EnterEducationAreas: "Enter other education areas",
  DiplomasandCertificates: "Diplomas and certificates",
  EnterDiplomasandCertificates: "Enter diplomas and certificates",
  ChooseSubCategory: "Choose sub category",
  Agreetoreceive: "Agree to receive recuritment ads ?",
  EnterAreaofInterest: "Enter area of interest (seprated by comma)",
  UploadImage: "Upload profile image",
  UserDashboard: "User dashboard",
  Logout: "Logout",
  PersonalNumber: "Personal number",
  Event: "Event",
  Price: "Price",
  VATCharges: "VAT charges",
  Total: "Total",
  NoNotification: "No notification found",
  EnterAdditionalAreasInterest: "Enter additional areas of interest",
  Additionalareasinterest: "Additional areas of interest",
  EnterExtraCurricular: "Enter extra curricular competence ",
  Coworker: "Co-worker",
  Teamleader: "Team leader",
  Manager: "Manager",
  Executivemanager: "Executive manager",
  Elementaryschool: "Elementary school",
  Grammarschool: "Grammar school",
  ProfessionalOccupationaltraining: "Professional/Occupational training",
  Bachelor: "Bachelor",
  Master: "Master",
  ChooseEVoiceAddress: "Choose e-invoice address",
  Phd: "Phd",
  NameCard: "Name on card",
  ChooseLan: "Choose language",
  MyProfile: "My profile",
  Membersince: "Member since ",
  MeetingDetails: "Meeting details",
  ChooseDob: "Choose DOB",
  ShowingCourses: "Showing courses within",
  CoursesFound: " Courses found",
  CourseCategory: "Course category",
  Organization: "Organization",
  FilterBy: "Filter by",
  Difficultylevel: "Difficulty-level",
  Newest: "Newest",
  Oldest: "Oldest",
  Free: "Free",
  Paid: "Paid",
  Beginner: "Beginner",
  Intermediate: "Intermediate",
  Advance: "Advance",
  PaybyInvoice: "Pay by invoice",
  Country: "Country",
  EnterCountry: "Enter country",
  EnterCity: "Enter city",
  EnterZip: "Enter ZIP code",
  Zip: "ZIP code",
  OrganizationBracket: "(organization)",
  Reference: "Reference at the paying organization (if applicable)",
  EnterReference: "Enter reference",
  EnterStreet: "Enter street & number",
  Street: "Street & number",
  StreetAndNumber: "Street & number",
  OrganizationNumber: "Organization number",
  EnterOrganizationNumber: "Enter organization number",
  OrganizationName: "Name of organization/company",
  EnterOrganizationName: "Enter organization name",
  NameOfStudent: "Name of student",
  PersonalNumber: "Personal number of student",
  EVoiceAddress: "E-invoice address",
  CoursesInterestIn: "Courses interest in",
  PayInvoice: "Pay by invoice",
  AllCategory: "All categories",
  NameOnCard: "Enter name on card",
  TryAgain: "Please try again !",
  SuccessEnroll: "You have successfully enrolled to this course",
  EventEnrollSuccess: "You have successfully enrolled to this event",
  VatCharges: "VAT charges ",
  VerifyMail: "Please verify your account through your email",
  ThanksRating: "Thank you for rating us",
  WhatEddiCanDo: "What eddi can do for you?",
  OppsAlreadyEnrolled: "Opps, you already enrolled this course ! ",
  Congratulations: "Congratulations ! ",
  AuthFailed: "Authentication failed!..",
  UnderProcess: "Transaction under process..",
  Failed: "Failed... !",
  PayByMe: "Pay by me",
  PayByOrg: "Pay by organization",
  SuccessEnrolledEvent: "You have successfully enrolled to this Event",
  OrganizationEmail: "Organization email",
  EnterOrganizationEmail: "Enter organization email",
  RecrutmentTitle: "Recruitment opportunities",
  CourseSubcategory: "Course subcategory",
  Accept: "ACCEPT",
  ClearFilters: "Clear filters",
  OrganizationCourse: "My organization",
  OrganizationCategory: "Organization categories",
  MyOrganization: "My organization",
  AllCourse: "All courses",
  StreetAndNumber: "Street & number",
  IncludeVATCharges: "including VAT charges",
  OrderConfirmation: "Order confirmation",
  PaidData: "Paid",
  FreeData: "Free",
  StartsFrom: "Starts from",
  Learners: "Learners",
  Days: "Days",
  WelcomeText:
    " In these steps you provide information that will form your personal profile at eddi. It will only take a few moments, and you can always return to make changes.",
  BackToHome: "Back to home",
  KeyHighlights: "Key highlights",
  CategoryOverview: "Category overview",
  EnrollmentProcess: "Enrollment process",
  Step1CreateAccount: "Step 1 - Create account",
  Step1CreateAccountDescrption:
    "Create an account with eddi platform by entering the required details.",
  Step2CreateAccount: "Step 2 - Create profile",
  Step2CreateAccountDescrption:
    "Create your personlized profile with eddi and choose area of your interest.",
  Step3CreateAccount: "Step 3 - Start searching courses",
  Step3CreateAccountDescrption:
    "Based on the profile, eddi will suggest the best match courses to the user.",
  ViewAllCourses: " View all courses",
  Nocoursefoundbasedonyourprofile: "No courses found based on your profile",
  NewsAndArticles: "News and articles",
  Online: "Online",
  Physical: "Physical",
  Hybrid: "Hybrid",
  InvoiceSentSuccessfully: "Invoice sent successfully",
  AlreadyEnrolled: "Already enrolled",
  ExternalCourseDetails:
    "Enrollment to this course is handled by the course supplier. Click below to be directed.",
  AdditionalareasinterestSeparatedByComma: "(separated by comma character)",
  ReadMoreAdvertisementButton: "Read more",
  StartOnward: "Start onward",
  ExternalEventDetails:
    "Great, that you want to enroll to this event. Registration is made with the organizer of the event.",
  ContinueButton: "Continue",
  Registered: "Registered",
  AboutOrganizer: "About organizer",
  PublishedBy: "Published by",
  OurCourseCategory: "Our course categories",
  ContactInstructor: "Contact instructor",
  CourseContentStatus: "Course content and status of completion",
  StartNow: "Start now",
  Download: "Download",
  EnterCorporateCode: "Enter corporate code",
  CorporateCode: "Corporate code",
  Playvideo: "Play video",
  OrganiZationEvent: "Organization event",
  OrgEventNews: "Organization event and news",
  EnrollmentToEvent: "Enrollment to Event",
  COURSECATEGORY: "COURSE CATEGORY",
  NotStarted: "Not started",
  ViewContent: "View content",
  Global: "Eddis courses",
  MyOrgCourse: "My organization courses",
  PaymentConfirmationPending: "Payment confirmation pending",
  ComingSoon: "Coming soon!",
  OurCourses: "Our courses",
  CourseCategories: "Course categories",
  By: "By",
  Education: "education",
  "Business and finance": "Business and finance",
  GRC: "GRC",
  "Society and technology": "Society and technology",
  "Legal and law": "Legal and law",
  "Safety academy": "Safety academy",
  "IT and information": "IT and information",
  "Marketing academy": "Marketing academy",
  "Executive education": "Executive education",
  Startsfrom: "Starts from",
  Pending: "Pending",
  Ongoing: "Ongoing",
  Completed: "Completed",
  Duration: "Duration",
  courses: "courses",
  //   "Nyaste":"Newest" ,
  //   "Äldsta":"Oldest",
  //    "Grundnivå":'Beginner',
  //    "Medelnivå":'Intermediate',
  //   "Avancerad nivå":'Advanced',
  // "Med avgift":'Paid',
  // "Utan avgift":"Free",
};
export default en;
